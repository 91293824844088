<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawers"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
    class="elevation-4"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list dense nav class="pb-3">
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img
            :src="imgUrl + siteInfo.logo"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="siteInfo.short_title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

   <v-list>
			<v-list-item link to="/">
				<v-list-item-icon>
					<v-icon>mdi-dns</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title v-text="$vuetify.lang.t('$vuetify.dashboard')"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
      
			<v-list-group 
				v-for="item in routes"
				:key="item.meta.title"
				v-model="item.active"
				:prepend-icon="item.meta.icon"
        append-icon=""
				no-action
        color="primary"
			>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title v-text="$vuetify.lang.t('$vuetify.' + item.meta.title)"></v-list-item-title>
					</v-list-item-content>
				</template>

				<v-list-item v-for="child in item.children" :key="child.meta.title" link :to="item.path + '/'+ child.path">
				<!-- <v-list-item v-for="child in item.children" :key="child.meta.title" link :to="child.path"> -->
					<v-list-item-content>
						<v-list-item-title v-text="$vuetify.lang.t('$vuetify.' + child.meta.title)"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import serverConfig from "@/utils/serverConfig";

export default {
  name: "DashboardCoreDrawer",
  
  data() {
    return{
      imgUrl: serverConfig.img_url,
    }
  },

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["barColor", "barImage", "drawer"]),
    ...mapGetters(["routers", "siteInfo"]),
    routes(){
      const r = this.routers.filter(route => route.children.filter(child => child.hidden === undefined).length)
      r.forEach(e => { e.children = e.children.filter(c => c.hidden === undefined) });
      return r 
    },
    drawers: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
